<script setup lang='ts'>
const props = withDefaults(defineProps<{
  isImg?: boolean | string | number | null
  isVideo?: boolean | string | number | null
  isAudio?: boolean | string | number | null
}>(), {
  isImg: false,
  isVideo: false,
  isAudio: false,
})
</script>

<template>
  <template v-if="props.isImg">
    <NPopover>
      <template #trigger>
        <i class="i-heroicons-solid:photograph mr-0.75 flex-shrink-0 text-blue-5" />
      </template>
      <span>有图片</span>
    </NPopover>
  </template>
  <template v-if="props.isVideo">
    <NPopover>
      <template #trigger>
        <i class="i-heroicons-solid:play mr-0.75 flex-shrink-0 text-blue-5" />
      </template>
      <span>有视频</span>
    </NPopover>
  </template>
  <template v-if="props.isAudio">
    <NPopover>
      <template #trigger>
        <i class="i-heroicons-solid:microphone mr-0.75 flex-shrink-0 text-blue-5" />
      </template>
      <span>有音频</span>
    </NPopover>
  </template>
</template>
